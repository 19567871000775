<template>
    <div class="product-info" style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
        <div class="service-body">
            <div class="service-left">
                <div class="head-card" style="margin-bottom: 10px">
                    <div>
                        <div class="work-title">
                            <div class="work-icon"></div>
                            <div>采购订单信息</div>
                        </div>
                    </div>
                    <div>
                        <div v-for="(item, index) in taskInfo.taskOrderList" :key="index" :style="index !== taskInfo.taskOrderList.length - 1 ? 'border-bottom: 1px #999999 solid' : ''" style=" padding: 20px">
                            <div style="display: flex; justify-content: space-between">
                                <div style="color: #031F88; font-size: 18px; margin-bottom: 10px">{{item.goodsName}}</div>
                                <div style="margin-right: 10px; color: #999999; margin-top: 10px">
                                    订单号：{{item.smallOrderNo}}
                                </div>
                            </div>
                            <div style="margin-bottom: 10px">
                            <span v-for="(key, index) in item.skuList.specValues" :key="index">
                                {{Object.keys(key)[0]}}: {{Object.values(key)[0]}}
                            </span>
                            </div>
                            <div style="display: flex; justify-content: space-between">
                                <div>总支付金额： <span style="color: #EE3F4D; font-size: 22px">￥ {{item.totalAmount}}</span></div>
                                <div>
                                    <div>数量：{{item.count}}</div>
                                    <div style="margin-top: 6px; color: #031F88" class="hand op">店铺：{{item.shopName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body-card">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>采购序列详情</div>
                    </div>
                    <div style="padding:20px 40px">
                        <div class="service-tab">
                            <div class="title">
                                序列名称
                            </div>
                            <div class="content">
                                {{taskInfo.title}}
                            </div>
                            <div class="title">
                                委托类型
                            </div>
                            <div class="content">
                                采购订单
                            </div>
                            <div class="title">
                                订单状态
                            </div>
                            <div v-if="taskInfo.status === 0" class="content" style="color: #7BC526">
                                进行中
                            </div>
                            <div v-if="taskInfo.status === 1" class="content" style="color: #7BC526">
                                已完成
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                委托方
                            </div>
                            <div class="content" style="color: #57AAFF" v-if="taskInfo.fUser">
                                {{taskInfo.fUser.nickName}}
                            </div>
                            <div class="title">
                                承接方
                            </div>
                            <div class="content" style="color: #57AAFF" v-if="taskInfo.trustee">
                                {{taskInfo.trustee.nickName}}
                            </div>
                            <div class="title">
                                来自room
                            </div>
                            <div class="content" style="color: #031F88">
                                {{taskInfo.roomName}}
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                委托详情
                            </div>
                            <div style="width: 90%">
                                {{taskInfo.detail}}
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                创建时间
                            </div>
                            <div class="content">
                                {{taskInfo.creatTime}}
                            </div>
                            <div class="title">
                                截止时间
                            </div>
                            <div class="content">
                                {{taskInfo.finishTime}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="service-right">
                <div class="body-card" style="margin: 0; min-height: 200px">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>订单动态</div>
                    </div>
                    <div style="padding:16px 20px">
                        <el-timeline>
                            <el-timeline-item v-for="(activity, index) in taskInfo.taskOrderLog" :key="index" color="#2970FF" size="large">
                                <span style="color: #2970FF">{{activity.remark}}</span>
                                <div style="margin-top: 10px">
                                    <div style="display: flex; margin-bottom: 10px">
                                        <div class="service-icon"></div><span>订单：{{activity.smallOrderNo}}</span>
                                    </div>
                                </div>
                                <div style="margin-top: 10px">
                                    <div style="display: flex; margin-bottom: 10px">
                                        <div class="service-icon"></div><span>{{activity.createTime}}</span>
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "productInfo",
    components: {},
    data() {
        return {
            taskInfo: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getTaskInfo()
    },
    methods: {
        // 获取任务详情
        getTaskInfo() {
            this.newApi.getTaskInfo({taskId: this.$route.params.id}).then(res => {
                this.taskInfo = res.data;
            })
        },
    }
}
</script>

<style scoped>
.service-body {
    width: 1500px;
    display: flex;
    margin: 110px auto;
}

.service-left {
    width: 1136px;
    margin-right: 10px;
}

.service-right {
    width: 354px;
    overflow: hidden;
}

.head-card {
    width: 100%;
    background-color: #FFFFFF;
}

.body-card {
    margin-bottom: 10px;
    width: 100%;
    background-color: #FFFFFF;
}

.service-body .work-title {
    display: flex;
    line-height: 20px;
    padding-top: 16px;
    font-size: 18px;
    font-weight: 600;
    color: #031F88;
}

.service-body .work-title .work-icon {
    width: 6px;
    height: 20px;
    background-color: #031F88;
    margin-right: 14px;
}

.service-tab {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px #E1E1E1 solid;
}

.service-body .title {
    width: 100px;
    color: #888888;
}

.service-body .content {
    width: 260px;
}

.service-body .service-icon {
    width: 6px;
    height: 6px;
    margin-top: 7px;
    margin-right: 6px;
    background-color: #2970FF;
    border-radius: 50%;
}
</style>